import { cloneDeep } from 'lodash';
import AbTest from '~/components/snippets/AbTest.vue';
import type { ISbLink, ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import * as Sentry from '@sentry/nuxt';

export type IExtendedSbLink = ISbLink & {
  path: string;
};

export const useStoryblokUtils = () => {
  const config = useRuntimeConfig();
  const route = useRoute();
  const i18n = useI18n();
  const app = useNuxtApp();
  const storyblokApi = useStoryblokApi();

  async function fetchStoryblokLocalizedPage(storyblokLinks: IExtendedSbLink[]) {
    const version =
      route.query._storyblok || config.public.rEnv !== 'production'
        ? 'draft'
        : 'published';

    let locale: string = i18n.locale.value === 'en' ? 'default' : i18n.locale.value;

    if (route.query._storyblok) {
      locale = route.query._storyblok_lang?.toString() ?? app.$getDefaultLocale();
    }

    // Did storyblok switch from default to what is defined as default e.g. "en" ??
    if (locale === 'default') locale = 'en';

    let fullSlug = route.path;

    if (app.$getLocaleFromPath(fullSlug)) {
      fullSlug = fullSlug.substring(3);
    }

    // Because Storyblok root homepage has path /home
    if (fullSlug === '/' || fullSlug === '') {
      fullSlug = '/home';
    }

    // We do not want any loose slashes at the start or end of a path
    if (fullSlug[0] === '/') {
      fullSlug = fullSlug.substring(1);
    }

    if (fullSlug.length > 0 && fullSlug[fullSlug.length - 1] === '/') {
      fullSlug = fullSlug.substring(0, fullSlug.length - 1);
    }

    const country = app.$getCountry();

    let useCountryPath = false;

    if (
      [
        'profile_unique_id',
        'invite_unique_id',
        'invite_unique_id_agreement',
        'object_view',
        'agreement_view',
      ].includes(route.name)
    ) {
      if (route.name === 'profile_unique_id') {
        fullSlug = 'tenants/profile';
      } else if (route.name === 'invite_unique_id') {
        fullSlug = 'invite';
      } else if (route.name === 'invite_unique_id_agreement') {
        fullSlug = 'invite';
      } else if (route.name === 'object_view') {
        fullSlug = 'dashboard/object';
      } else if (route.name === 'agreement_view') {
        fullSlug = 'dashboard/agreement';
      }
    }

    const countryPath =
      country === 'pl' ? `poland` + `/${fullSlug}` : `estonia` + `/${fullSlug}`;

    if (Array.isArray(storyblokLinks)) {
      storyblokLinks.forEach(function (value) {
        if (value.path === countryPath) {
          if (!useCountryPath) useCountryPath = true;
        }
      });
    } else {
      console.warn('fetchStoryblokLocalizedPage: Storyblok Links not found');
    }

    if (useCountryPath) {
      fullSlug = countryPath;
    }

    let story;
    const resolveRelations = [];
    resolveRelations.push('ArticleItems.highLightedArticle');
    resolveRelations.push('ArticlesSuggestion.suggestedArticles');

    const options: ISbStoriesParams = {
      version: version,
      language: locale === 'en' ? 'default' : locale,
      resolve_relations: resolveRelations,
      // fallback_lang: context.app.$getDefaultLocale()
    };

    // fallback_lang is causing problem of loading EN language on estonian website at /get-app

    // console.log(`cdn/stories/${fullSlug}`, options)

    try {
      const response = await storyblokApi.get(`cdn/stories/${fullSlug}`, options);
      story = response.data.story;

      // Remapping few fields from root story object to "blok" - remains to be seen if there are more root fields required
      // If more than couple needed, we should refactor our component "blok" prop (maybe whole story should be passed)
      story.content.first_published_at = story.first_published_at;
      story.content.story_id = story.id;

      if (isAbTestingEnabled(story)) {
        story = await parseStoryForAbTesting(story);
      }
    } catch (e) {
      if (!route.path.includes('not-found')) {
        if (e) {
          Sentry.captureException(new Error(`404: ${fullSlug}`));
        }

        if (app.$getDefaultLocale() === i18n.locale.value) {
          navigateTo('/not-found');
        } else {
          navigateTo(`/${i18n.locale.value}/not-found`);
        }
      }
    }

    return story;
  }

  /* Recursive function */
  async function parseBloksArrayForAbTesting(
    bloksArray: unknown,
  ): Promise<Array<unknown>> {
    /* I think we cannot optimize here to maintain recursivity? */
    // const containsAbTestContentBlok = bloksArray.some((x) => x.component === AbTest.name);
    // if (!containsAbTestContentBlok) return bloksArray;

    if (!Array.isArray(bloksArray)) {
      return [];
    }

    const parsedContentArray = [];
    for (let i = 0; i < bloksArray.length; i++) {
      const blok = bloksArray[i];

      /* We only want to iterate over Object values if it's typeof Component */
      if (blok.component) {
        for (const [key, value] of Object.entries(blok)) {
          if (Array.isArray(value)) {
            blok[key] = await parseBloksArrayForAbTesting(value);
          }
        }
      }

      if (!blok || blok.component !== AbTest.name) {
        parsedContentArray.push(blok);
        continue;
      }

      //const experimentId = blok.experiment.experimentFeatureFlagKey;
      const variationGroupId = blok.experiment.variationGroupId;

      // TODO - migration - enable testing suite
      /*      const featureFlag = await app.$testingSuite.getFeatureFlag({
        experimentId: experimentId,
      });*/
      const featureFlag = null;

      /* If this feature flag does not exist, something has gone wrong and fall back to "control" test blok */
      const testBlokEnabled = featureFlag
        ? variationGroupId === featureFlag
        : variationGroupId === 'control';

      if (testBlokEnabled) {
        const content = await parseBloksArrayForAbTesting(blok.content);
        parsedContentArray.push(...content);
      }
    }

    /* TODO - do we need to implement fallback when no blocks are retrieved for experiment */

    return parsedContentArray;
  }

  async function parseStoryForAbTesting(story: ISbStoryData) {
    if (!story) return story;

    /*  Deep clone since Storyblok-Nuxt does caching by ref, not value... */
    const parsedStory = cloneDeep(story);

    for (const [key, value] of Object.entries(parsedStory.content)) {
      if (Array.isArray(value)) {
        parsedStory.content[key] = await parseBloksArrayForAbTesting(value);
      }
    }

    return parsedStory;
  }

  function buildHeadMetaTagsFromStoryblokPage(story: ISbStoryData) {
    return {
      htmlAttrs: {
        lang: `${i18n.locale.value}-${app.$getCountry().toUpperCase()}`,
      },
      title: story && story.content && story.content.SEO ? story.content.SEO.title : '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            story && story.content && story.content.SEO
              ? story.content.SEO.description
              : '',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content:
            story && story.content && story.content.SEO ? story.content.SEO.title : '',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            story && story.content && story.content.SEO
              ? story.content.SEO.description
              : '',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${app.$getHost()}${route.path}`,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            story && story.content && story.content.SEO
              ? story.content.SEO.og_image
              : '',
        },
        {
          hid: 'i18n-og',
          property: 'og:locale',
          content: `${i18n.locale.value}_${app.$getCountry().toUpperCase()}`,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'robots',
          name: 'robots',
          content:
            story && story.content && story.content.metaRobots
              ? story.content.metaRobots
              : '',
        },
      ],
    };
  }

  return {
    fetchStoryblokLocalizedPage,
    buildHeadMetaTagsFromStoryblokPage,
  };
};

function isAbTestingEnabled(story: ISbStoryData) {
  return story.content && story.content.abTestingEnabled;
}
